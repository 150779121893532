import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientDetailsProfileRuleKeyword } from '@models/client'
import { StaticRules } from '@helpers/rules'
import { ExpandableText } from './expandable-text'

export const MARKETING_RULES_KEYWORDS = [
  'email_rules',
  'sms_rules',
  'phone_call_rules',
] as ClientDetailsProfileRuleKeyword[]

export const MarketingAgreementSection = (): JSX.Element | null => {
  const { control, setValue } = useFormContext()

  const formMarketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = formMarketingRules.filter(Boolean)
  const isEachSelected = formMarketingRules.every(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked

    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    setValue('allMarketingRulesSelected', isEachSelected)
  }, [isEachSelected])

  return (
    <>
      <Checkbox
        className="checkbox--small font-size-sm is-muted-label align-items-start"
        name="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        indeterminate={isAnyMarketingRuleSelected}
        label={
          <div className="mt-1">
            <div className="mb-1">Zgoda na przetwarzanie danych w celach marketingowych</div>
            <div className="font-size-sm fw-normal">
              <ExpandableText text={StaticRules.marketing_rules} initialHeight={26} />
            </div>
          </div>
        }
      />

      <div className="mt-1" style={{ marginLeft: 29 }}>
        <p className="font-size-sm mb-1 col-lg-9 text-darker-gray">
          {StaticRules.marketing_rules_communication_channel}
        </p>
        <MarketingRule name="phone_call_rules">telefonu</MarketingRule>
        <div className="mt-2 mb-1 pl-2 text-darker-gray font-size-xs">
          środków komunikacji elektronicznej, w szczególności:
        </div>
        <MarketingRule name="email_rules">wiadomości email</MarketingRule>
        <MarketingRule name="sms_rules">wiadomości SMS</MarketingRule>
      </div>
    </>
  )
}

const MarketingRule = ({ name, children }: { name: ClientDetailsProfileRuleKeyword; children: React.ReactNode }) => (
  <Checkbox
    name={name}
    id={name}
    className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
    label={
      <div className="mt-1 col-12 col-sm-8">
        <div className="pb-1">{children}</div>
      </div>
    }
  />
)
