import * as React from 'react'
import { MouseEvent } from 'react'
import { useRules } from '@hooks/use-rules'
import { useLink } from '@hooks/use-link'
import { AvailableRuleKeyword } from '@models/app-data'

export const useRulesContent = (): Partial<Record<AvailableRuleKeyword, React.ReactNode>> => {
  const { getRules } = useRules()
  const { openLink } = useLink()

  const [animals_rules, main_rules, departure_voucher_rules, information_clause, services_rules] = getRules([
    'animals_rules',
    'main_rules',
    'departure_voucher_rules',
    'information_clause',
    'services_rules',
  ])

  const handleClick = (url: string | undefined) => (event: MouseEvent) => {
    event.preventDefault()
    openLink(url)
  }

  return {
    animals_rules: (
      <span>
        Oświadczam, że zapoznałem się z treścią dokumentu
        <span
          className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
          onClick={handleClick(animals_rules?.url)}
        >
          {animals_rules?.name}
        </span>
        i akceptuję jego postanowienia.
      </span>
    ),
    main_rules: (
      <span>
        Oświadczam, że zapoznałem się z treścią dokumentu
        <span
          className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
          onClick={handleClick(main_rules?.url)}
        >
          {main_rules?.name}
        </span>
        i akceptuję jego postanowienia.
      </span>
    ),
    departure_voucher_rules: (
      <span>
        Oświadczam, że zapoznałem się z treścią dokumentu
        <span
          className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
          onClick={handleClick(departure_voucher_rules?.url)}
        >
          {departure_voucher_rules?.name}
        </span>
        i akceptuję jego postanowienia.
      </span>
    ),
    information_clause: (
      <span>
        Oświadczam, że zapoznałem się z treścią dokumentu
        <span
          className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
          onClick={handleClick(information_clause?.url)}
        >
          {information_clause?.name}
        </span>
        i akceptuję jego postanowienia.
      </span>
    ),
    services_rules: (
      <span>
        Oświadczam, że zapoznałem się z treścią dokumentu
        <span
          className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
          onClick={handleClick(services_rules?.url)}
        >
          {services_rules?.name}
        </span>
        i akceptuję jego postanowienia.
      </span>
    ),
  }
}
