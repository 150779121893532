import { isParking } from '@helpers/improvement-helper'
import { ReservationDetails, Rule } from '@models/reservation'
import { ClientDetailsProfileRuleKeyword } from '@models/client'
import { AvailableRuleKeyword } from '@models/app-data'

export const checkGuestsFilled = (reservationDetails: ReservationDetails | undefined) => {
  if (!reservationDetails) return false
  return reservationDetails.guests.length > 0
}

export const checkParkingFilled = (reservationDetails: ReservationDetails | undefined): boolean => {
  if (!reservationDetails) return false
  const { prices, cars } = reservationDetails
  const carsWithPlates = cars.filter(({ register_number }) => register_number)
  const parkingAmount = prices.improvements.items.filter(isParking).reduce((acc, curr) => acc + curr.amount, 0)
  return parkingAmount > 0 && parkingAmount === carsWithPlates.length
}

export const getRuleValues = (rules: Rule<AvailableRuleKeyword>[], context = '') =>
  rules.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr.keyword}-${context}`]: `${curr.accepted}`,
    }),
    {},
  )

export const isEachRequiredRuleAccepted = (rules: Rule<AvailableRuleKeyword>[]): boolean =>
  rules
    .filter((rule: Rule<AvailableRuleKeyword>) => rule.required)
    .every((rule: Rule<AvailableRuleKeyword>) => rule.accepted)

export const isEachRulesRequiredForReservationAccepted = (
  reservationDetailsRules: Rule<AvailableRuleKeyword>[],
  clientProfileRules: Rule<ClientDetailsProfileRuleKeyword>[],
): boolean => isEachRequiredRuleAccepted([...reservationDetailsRules, ...clientProfileRules])
