import { commonObjectPatch } from '@api/basic-requests'
import { ReservationDetails } from '@models/reservation'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientProfileRules } from '@store/selectors/client-selectors'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { useApiRequest } from '@hooks/use-api-request'

interface Response {
  forwardRules: (reservationDetails: ReservationDetails) => void
  isForwarding: boolean
}

export const useForwardProfileRulesToReservation = (): Response => {
  const clientProfileRules = useSelector(selectClientProfileRules)

  const dispatch = useDispatch()

  const { action: forwardRules, isLoading: isForwarding } = useApiRequest(
    async (reservationDetails: ReservationDetails) => {
      const profileAcceptedRules = clientProfileRules.filter(rule => rule.required && rule.accepted)

      const rulesToForward = reservationDetails.rules.filter(
        reservationRule =>
          reservationRule.required &&
          !reservationRule.accepted &&
          profileAcceptedRules.some(rule => rule.keyword === reservationRule.keyword),
      )

      if (rulesToForward.length) {
        const rules = rulesToForward.reduce((prev, rule) => ({ ...prev, [rule.keyword]: true }), {})
        dispatch(setReservationDetails(await commonObjectPatch(reservationDetails.urls.rules, rules)))
      }
    },
  )

  return {
    forwardRules,
    isForwarding,
  }
}
