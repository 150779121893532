import { AvailableRuleKeyword } from '@models/app-data'
import { Rule } from '@models/reservation'
import { ClientDetailsProfileRuleKeyword } from '@models/client'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'

const AppUsageRequiredRulesKeywords: Partial<AvailableRuleKeyword>[] = ['information_clause', 'services_rules']

export const useAppUsageRulesCheck = () => {
  const clientDetails = useSelector(selectClientDetails)

  const requiredRulesAccepted = clientDetails.profile.rules.reduce(
    (accepted: boolean, rule: Rule<ClientDetailsProfileRuleKeyword>) => {
      if (AppUsageRequiredRulesKeywords.includes(rule.keyword)) {
        return accepted && rule.accepted
      }

      return accepted
    },
    !!clientDetails.profile.rules.length,
  )

  return { requiredRulesAccepted }
}
