import * as React from 'react'
import * as clsx from 'clsx'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { useRules } from '@hooks/use-rules'
import { AvailableRuleKeyword } from '@models/app-data'
import { ExpandableText } from '@components/expandable-text'
import { StaticRules } from '@helpers/rules'
import { PAYMENT_TERMS_KEY, PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'
import { useLink } from '@hooks/use-link'
import { MarketingAgreementSection } from '@components/marketing-agreements-section'

export interface AgreementSectionProps extends PaymentModalAgreements {
  wrapperClassName?: string
  className?: string
  children?: React.ReactNode
  optionalProductRules?: AvailableRuleKeyword[]
  requiredProductRules?: AvailableRuleKeyword[]
  omitPaymentRules?: boolean
}
export const BASE_RULES = ['main_rules', 'email_rules', 'sms_rules', 'phone_call_rules', 'information_clause']

export const AgreementSection = ({
  wrapperClassName,
  className,
  children,
  paymentMethod,
  onProductAgreementsChange,
  optionalProductRules,
  requiredProductRules,
  omitPaymentRules,
}: AgreementSectionProps): JSX.Element | null => {
  const { setValue, watch, control } = useFormContext()

  const { getRules } = useRules()
  const { openLink } = useLink()

  const productRules = [...(optionalProductRules ?? []), ...(requiredProductRules ?? [])]
  const rulesToCheck = [...(productRules.length ? productRules : []), ...(omitPaymentRules ? [] : [PAYMENT_TERMS_KEY])]

  const rules = watch(rulesToCheck)
  const isEachSelected = rules.every(Boolean)

  const handleSelectAll = () => {
    rulesToCheck.forEach(additionalRule => {
      setValue(additionalRule, !isEachSelected)
    })
  }

  const requiredRules = useWatch({
    control,
    name: [...(requiredProductRules || []), ...(omitPaymentRules ? [] : [PAYMENT_TERMS_KEY])],
  })

  const [mainRules, informationClause] = getRules(['main_rules', 'information_clause'])

  const showRule = (rule: AvailableRuleKeyword) => rulesToCheck.some(el => el === rule)

  React.useEffect(() => {
    onProductAgreementsChange(requiredRules.every(Boolean))
  }, [requiredRules])

  if (rulesToCheck.length === 1 && rulesToCheck[0] === PAYMENT_TERMS_KEY && !paymentMethod) return null

  const handleLinkClick = (url: string | undefined) => (event: React.MouseEvent) => {
    event.preventDefault()
    openLink(url)
  }

  return (
    <div className={clsx('w-100', wrapperClassName)}>
      <strong className="text-gold d-block mt-4">Akceptacja regulaminów i zasad promocji:</strong>
      <div className={clsx('mt-3', className)}>
        {rulesToCheck.length > 1 && (
          <Checkbox
            name="all"
            label="Zaznacz wszystkie"
            className="font-700"
            onClick={handleSelectAll}
            checked={isEachSelected}
          />
        )}
        <div className="ms-4 pt-2 font-xs d-flex flex-column gap-2">
          {showRule('main_rules') && (
            <Checkbox
              className="checkbox--small"
              name="main_rules"
              label={
                <span className="font-size-sm">
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <span
                    className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
                    onClick={handleLinkClick(mainRules?.url)}
                  >
                    {mainRules?.name}
                  </span>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          )}
          {showRule('information_clause') && (
            <Checkbox
              className="checkbox--small"
              name="information_clause"
              label={
                <span className="font-size-sm">
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <span
                    className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
                    onClick={handleLinkClick(informationClause?.url)}
                  >
                    {informationClause?.name}
                  </span>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          )}
          {children}

          {paymentMethod && (
            <Checkbox
              name={PAYMENT_TERMS_KEY}
              label={StaticRules[paymentMethod?.provider]}
              className="checkbox--small font-size-md"
              wrapperClassName="col-12"
            />
          )}

          <MarketingAgreementSection />

          {paymentMethod?.provider === 'payu' && (
            <ExpandableText
              wrapperClassName="wallet__method__expandable-privacy mb-4"
              text={StaticRules.payu_privacy_policy}
            />
          )}
        </div>
      </div>
    </div>
  )
}
